import React from 'react'
import {} from 'react-bootstrap'

//components
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactCard from '../components/contact-card'
import SocialBadge from '../components/social-badge'

// resources
import location_png from '../images/icons/pin.png'
import send_png from '../images/icons/send.png'
import social_png from '../images/icons/love.png'

import facebook_png from '../images/icons/social/Facebook.png'
import instagram_png from '../images/icons/social/Instagram.png'

const Contact = () => (
    <Layout>
      <SEO title="Contact" />
      <div className="row justify-content-center mb-5">
        <h1 className="text-center"> How to Reach Us: </h1>
      </div>
  
      <div className="row justify-content-center">
        <ContactCard title="Sandy Location" icon={location_png} alt="Sandy Location" >
              9275 South 700 East <br />
              Sandy, UT 84070 <br />
              <a href="" > Google Maps </a>
        </ContactCard>
  
        <ContactCard title="By Phone or Email" icon={send_png} alt="Email/Phone">
              <b>Phone:</b> <br />(801) 913-2658
              <br /> <br />
              <b>Email:</b> <br />info@carouselhat.com
        </ContactCard>
  
        <ContactCard title="Our Social Media" icon={social_png} alt="Social media">
            <a href="https://www.facebook.com/carouselhat/" > <SocialBadge src={facebook_png} alt="Facebook" /> Facebook </a> <br />
            <a href="https://www.instagram.com/carouselhat/" > <SocialBadge src={instagram_png} alt="Instagram" /> Instagram </a>
        </ContactCard>
      </div>
    </Layout>
  )
  
  export default Contact