import React from "react"

import * as CardStyle from '../styles/modules/contact.module.scss'
import {Row, Col, } from 'react-bootstrap'

const ServiceCard = ({ title, icon, alt, children }) => (
    
    <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
        <div className="card h-100 pb-3" >
            <div className="card-body bg-white">
                <h4 className="card-title text-center">{title}</h4>
                <hr className="mx-5" />
                <div className="row justify-items-center">
                    <div className="col-sm-4">
                        <img className={CardStyle.icon + ' img-fluid img-responsive'} src={icon} alt={alt} />
                    </div>
                    <div className="col-sm-8">
                        <p>{children}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ServiceCard

// <Col lg="4" > </Col>


/*  
    src="http://placehold.it/400x200"
     
*/